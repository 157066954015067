* {
  box-sizing: border-box;
}

body, p {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

li {
  margin: 0;
  padding: 0;
}

a {
  color: #5e1919;
}

:root {
  --font-stack: "Arial", sans-serif;
  --color-txt: #5e1919;
  --color-bg: #fff;
  --header-height: 100px;
  --footer-height: 90px;
  --margin-main: 25px;
  --padding-main: 25px;
}

.margin-main {
  margin: var(--margin-main);
}

.padding-main {
  padding: var(--padding-main);
}

.flex {
  display: flex;
}

.flex-center-x {
  justify-content: center;
  display: flex;
}

.flex-center-y {
  align-items: center;
  display: flex;
}

.flex-center {
  justify-content: center;
  align-items: center;
  display: flex;
}

.flex-center-y-justify-x {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

body {
  font: 26px var(--font-stack);
  color: var(--color-txt);
  background: url("../RadioLoss.29a2419a.jpg") center / cover;
  width: 100vw;
  height: 100vh;
  font-stretch: condensed;
  overflow: hidden;
}

header {
  height: var(--header-height);
  filter: blur(2px);
  -webkit-user-select: none;
  user-select: none;
  z-index: 100;
  pointer-events: none;
  width: 100%;
  font-size: 52px;
  font-weight: bold;
  position: fixed;
  top: 0;
}

main {
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  overflow-y: scroll;
}

main::-webkit-scrollbar {
  width: 10px;
}

main::-webkit-scrollbar-thumb {
  background: var(--color-txt);
  cursor: pointer;
}

main::-webkit-scrollbar-thumb:hover {
  background: var(--color-txt);
}

footer {
  height: var(--footer-height);
  filter: blur(3px);
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  width: 100%;
  font-size: 52px;
  font-weight: bold;
  position: fixed;
  bottom: 0;
}

#playButton {
  z-index: 100;
  cursor: pointer;
  pointer-events: all;
}

.audioTimeline {
  z-index: 100;
  cursor: pointer;
  width: 100%;
  height: 10px;
  position: fixed;
  bottom: 0;
  left: 0;
}

.audioTimelineProgress {
  background-color: #5e1919;
  width: 0%;
  height: 100%;
}

.info {
  box-sizing: border-box;
  padding-top: 100px;
  padding-bottom: 100px;
}

.infoInner {
  opacity: .75;
  gap: var(--padding-main);
  background-color: #ddd;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  font-family: Times New Roman, Times, serif;
  display: flex;
  box-shadow: 0 0 10px 5px #5e191980;
}

.info p {
  text-indent: 50px;
}

ul {
  width: 100%;
  font-family: monospace;
  font-size: 16px;
}

.source {
  display: flex;
}

.sourceTime {
  width: 15%;
}

.sourceArtist {
  width: 25%;
  padding-right: 10px;
}

.sourceTitle {
  width: 45%;
  padding-right: 10px;
}

.sourceDate {
  text-align: right;
  width: 15%;
}

.lostForever {
  filter: blur(5px);
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}

.alertBox {
  color: #ddd;
  pointer-events: none;
  background-color: #5e1919;
  justify-content: center;
  align-items: center;
  width: 500px;
  height: 200px;
  font-family: Times New Roman, Times, serif;
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.credits, .credits a {
  color: #666;
  font-size: 24px;
}

.source {
  cursor: pointer;
}

@media screen and (width <= 1200px) {
  body {
    font: 20px var(--font-stack);
  }

  header, footer {
    filter: blur(2px);
    height: 60px;
    font-size: 32px;
  }

  .info {
    box-sizing: border-box;
    padding-top: 60px;
    padding-bottom: 120px;
  }

  .credits, .credits a {
    font-size: 20px;
  }

  .source {
    text-align: center;
    flex-direction: column;
  }

  ul li {
    padding-bottom: 25px;
  }

  .sourceTime {
    width: 100%;
  }

  .sourceArtist, .sourceTitle {
    width: 100%;
    padding-right: 0;
  }

  .sourceDate {
    text-align: center;
    width: 100%;
  }
}

.transition-fade {
  opacity: 1;
  transition: all .4s;
}

html.is-animating .transition-fade {
  opacity: 0;
}
/*# sourceMappingURL=main.css.map */
