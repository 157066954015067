/* variables */

:root {
	--font-stack: "Arial", sans-serif;
	
	--color-txt: #5e1919;
	--color-bg: #fff;
	
	--header-height: 100px;
	--footer-height: 90px;
	
	--margin-main: 25px;
	--padding-main: 25px;
}




/* utilities */

.margin-main {
	margin: var(--margin-main);
}

.padding-main {
	padding: var(--padding-main);
}

.flex {
	display: flex;
}

.flex-center-x {
	display: flex;
	justify-content: center;
}

.flex-center-y {
	display: flex;
	align-items: center;
}

.flex-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.flex-center-y-justify-x {
	display: flex;
	justify-content: space-between;
	align-items: center;
}