/* resetting styles */

* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

p {
  margin: 0;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  margin: 0;
  padding: 0;
}

a {
  color: #5e1919;
}