/* import files */
@import url("./reset.css");
@import url("./utils.css");
/* @import "node_modules/@glidejs/glide/src/assets/sass/glide.core" */

/* styles */
body {
  height: 100vh;
  width: 100vw;
  font: 26px var(--font-stack);
  font-stretch: condensed;
  color: var(--color-txt);
  background: url("../images/RadioLoss.jpg");
  background-position: center;
  background-size: cover;
  overflow: hidden;
}

header {
  position: fixed;
  top: 0;
  width: 100%;
  height: var(--header-height);
  filter: blur(2px);
  font-size: 52px;
  font-weight: bold;
  user-select: none;
  z-index: 100;
  pointer-events: none;
}

main {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
  overflow-y: scroll;
}

/* width */
main::-webkit-scrollbar {
  width: 10px;
}

/* Track */
/* main::-webkit-scrollbar-track {
  background: #f1f1f1;
} */

/* Handle */
main::-webkit-scrollbar-thumb {
  background: var(--color-txt);
  cursor: pointer;
}

/* Handle on hover */
main::-webkit-scrollbar-thumb:hover {
  background: var(--color-txt);
}

footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: var(--footer-height);
  filter: blur(3px);
  font-size: 52px;
  font-weight: bold;
  user-select: none;
  pointer-events: none;
}

#playButton {
  z-index: 100;
  cursor: pointer;
  pointer-events: all;
}

.audioTimeline {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  z-index: 100;
  cursor: pointer;
}

.audioTimelineProgress {
  height: 100%;
  background-color: #5e1919;
  width: 0%;
}

.info {
  padding-top: 100px;
  padding-bottom: 100px;
  box-sizing: border-box;
}

.infoInner {
  box-shadow: 0 0 10px 5px rgba(94, 25, 25, 0.5);
  background-color: #ddd;
  opacity: 0.75;
  display: flex;
  flex-direction: column;
  gap: var(--padding-main);
  font-family: "Times New Roman", Times, serif;
  margin: 0 auto;
  width: 100%;
  max-width: 800px;
}

.info p {
  text-indent: 50px;
}

ul {
  width: 100%;
  font-family: monospace;
  font-size: 16px;
}

.source {
  display: flex;
}

.sourceTime {
  width: 15%;
}

.sourceArtist {
  width: 25%;
  padding-right: 10px;
}

.sourceTitle {
  width: 45%;
  padding-right: 10px;
}

.sourceDate {
  width: 15%;
  text-align: right;
}

.lostForever {
  filter: blur(5px);
  user-select: none;
  cursor: pointer;
}

.alertBox {
  position: fixed;
  width: 500px;
  height: 200px;
  background-color: #5e1919;
  color: #ddd;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Times New Roman", Times, serif;
  pointer-events: none;
}

.credits,
.credits a {
  color: #666;
  font-size: 24px;
}

.source {
  cursor: pointer;
}

@media screen and (max-width: 1200px) {
  body {
    font: 20px var(--font-stack);
  }

  header {
    height: 60px;
    filter: blur(2px);
    font-size: 32px;
  }

  footer {
    font-size: 32px;
    height: 60px;
    filter: blur(2px);
  }

  .info {
    padding-top: 60px;
    padding-bottom: 120px;
    box-sizing: border-box;
  }

  .credits,
  .credits a {
    font-size: 20px;
  }

  .source {
    flex-direction: column;
    text-align: center;
  }

  ul li {
    padding-bottom: 25px;
  }

  .sourceTime {
    width: 100%;
  }

  .sourceArtist {
    padding-right: 0px;
    width: 100%;
  }

  .sourceTitle {
    padding-right: 0px;
    width: 100%;
  }

  .sourceDate {
    width: 100%;
    text-align: center;
  }
}

/* swup */
.transition-fade {
  transition: 0.4s;
  opacity: 1;
}

html.is-animating .transition-fade {
  opacity: 0;
}
